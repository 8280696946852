.notification-page { 

}

.notification-page .no-notification
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: indigo;
  text-align: center;
  font-weight: bold;
}

@media (max-width: 991.98px) {
  .notification-item {
    padding : 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}