.connect-page .tab-header Button{
    background-color: transparent;
    border-color: transparent;
}
.connect-page .search-header{
    margin-top: 10px;
    margin-bottom: 10px;
}
.connect-page .tab-header {
    margin: 10px 0px;
}
.connect-page .tab-header .tab-text-selected{
    color: indigo;
    font-size: 20px;
    font-weight: bold;
}

.connect-page .tab-header .tab-text{
    color: gray;
    font-size: 20px;
    font-weight: bold;
}

.connect-page .no-event{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: indigo;
  text-align: center;
  font-weight: bold;
}
.connect-page .text-center{
    display: flex;
}
.connect-page .apply-filter{
    background-color: #75EEB7;
    border-radius: 25px;
    color: indigo;
}
.connect-page .clear-filter{
    border-color: indigo;
    border-radius: 25px;
    color : indigo;
    margin-left: 15px;
}
.connect-page .search-input{
    border: solid 2px indigo;
    border-radius: 25px;
}
.connect-page .search-input .form-control{
    border-color: transparent;
}
.connect-page .search-input .btn-outline-secondary{
    border-color: transparent;
}
.connect-page .search-input .form-control:hover{
    border-color: transparent;
}
@media screen and (max-width: 991px) {
    .container {
        width: 100% !important;
    }
 }
