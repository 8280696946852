.event {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  padding: 0;
}

.event .imageTitle {
  position: relative;
}

.event .imageTitle img {
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.event .name {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  padding: 1%;
  padding-left: 4%;
  width: 100%;
  font-size: 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
}

.event hr {
  border: 5px solid rgb(167, 166, 166);
  margin: 0;
}

.event .info {
  padding: 4% 7%;
}

.event .details {
  text-align: left;
  margin-bottom: 2%;
}

.event .details p, .event .more {
  color: indigo;
}

.event .details .detail {
  padding: 1% 0;
  font-weight: 800;
}

.event .details p {
  display: inline;
}

.event .details p.tag {
  color: rgb(122, 43, 226);
}

.event .details p svg {
  margin-right: 1%;
  margin-left: 2%;
}

.event .more {
  margin-left: 1%;
  text-decoration: underline;
  font-weight: 800;
}

.event .button {
  background-color: #75EEB7;
  border: none;
  border-radius: 25px;
  color : indigo;
  padding: 2%;
  width: 100%;
}
.event .title{
  color: indigo;
  font-size: medium;
  font-weight: bold;
}

.event svg {
  font-size: 30px;
}

.event .event-detail p{
  color: indigo;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.event .event-detail Col{
  display: flex;
  flex-direction: row;
  color: indigo;
}
.event .event-detail p span{
  color: indigo;
  font-weight: bold;
  margin-left: 3px;
}

.events-search .input-group .form-control{
  border : solid 1px indigo;
  border-radius : 20px;
}
.events-search .input-group .input-group-append{
  border-color: transparent;
}
.events-search .input-group .input-group-append button{
  background-color: transparent;
  border-color: transparent;
}
.form-group .form-control{
  border : solid 2px indigo;
  border-radius: 25px;
}

@media screen and (max-width: 600px) {
  .event .event-detail p{
    color: indigo;
    font-weight: bold;
    font-size: 10px;
  } 
  .event .event-detail p .icon{
    height: 2em;
  }
}