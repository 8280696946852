.event-list-row {
  position: relative;
}

.event-list-row::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 29px;
  border-left: 2px dotted lightgray;
}

.event-list-row:first-of-type:before {
  top: 30px;
}

.event-list-row:last-of-type:before {
  bottom: 70px;
}

.event-list-row::after {
  content: '';
  display: block;
  position: absolute;
  top: 20px;
  left: 20px;
  width: 20px;
  height: 20px;
  background: indigo;
  border-radius: 50%;
}
