.event-card {
  border: none;
  border-radius: .75rem;
  box-shadow: 1.5px 3px 3px 3px rgba(0, 0, 0, 0.15);
}

.event-card_hero {
  background: rgb(255, 162, 103);
  background: linear-gradient(151deg, rgb(255,162,103) 10%, rgb(0,108,74) 90%);
}

.event-card > .card-footer {
  background: #402366;
  border-radius: 0 0 .75rem .75rem;
}
