.user-profile-page .profile-info {
  background-color: indigo;
  color: white;
  text-align: center;
  padding: 2%;
  position: relative;
}

.user-profile-page .profile-info > .row {
  align-items: center;
}

.user-profile-page .profile-info img {
  border: 2px solid white;
  width: 100px;
  height: 100px;
}

.user-profile-page .profile-info .name-location {
  margin-top: 5%;
}

.user-profile-page .profile-info .name-location p {
  margin-bottom: 0;
}

.user-profile-page .profile-info .name-location p:first-of-type {
  font-weight: bolder;
  font-size: 1.3em;
}


.user-profile-page .profile-info .edit-profile {
  padding: 0;
}

/* .user-profile-page .profile-info .edit-profile button {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(211, 211, 211, 0.5);
  border: none;
  border-radius: 0;
  padding: 0.5% 2%;
  font-size: 0.8em;
  width: 50%;
} */

.user-profile-page .profile-info .edit-profile button p {
  font-size: 1%;
  display: inline;
}
.user-profile-page .profile-info .edit-profile .btn-edit {
  border : solid 1px indigo;
  background-color : white;
  border-radius: 25px;
  padding-left: 15%;
  padding-right: 15%;
  margin: 3%;
  color : indigo;
  min-width: 150px;
}

.user-profile-page .profile-info .edit-profile .btn-settings {
    border : solid 1px indigo;
    background-color : #75EEB7;
    border-radius: 25px;
    padding-left: 15%;
    padding-right: 15%;
    margin: 3%;
    color : indigo;
    margin-top: 20px;
    min-width: 150px;
}
.user-profile-page .badge-text {
  font-size: medium;
  font-weight: bold;
  color: rgb(49, 0, 83);
}

.user-profile-page .profile-info .action-buttons button {
  background-color: rgb(49, 0, 83);
  border: none;
  border-radius: 0;
  padding: 5%;
  width: 80%;
  margin: 3%;
}

.user-profile-page .profile-subsections .action-buttons button {
  background-color: transparent;
  border: solid 1px indigo;
  border-radius: 25px;
  padding-left: 15%;
  padding-right: 15%;
  margin: 3%;
  color : indigo;
}

.user-profile-page .profile-subsections .score-text{
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.user-profile-page .profile-info .action-buttons button p {
  margin-left: 3%;
  display: inline;
}

.user-profile-page .profile-info .profile-bio {
  margin-top: 3%;
}

.user-profile-page .profile-info .social-media span > div{
  display: inline;
}

.user-profile-page .profile-info .social-media .circle-icon {
  border-radius: 50%;
  background-color: lightgray;
  width: 30px;
  height: 30px;
  color: rgb(49, 0, 83);
}
.dialog-button{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-profile-page .profile-subsections .container {
  margin: 5% 0;
  box-shadow: -2px 0 3px lightgray, 0 2px 3px lightgray, 2px 0 3px lightgray;

  /* placeholders */
  height: auto;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
}
.user-profile-page .profile-subsections .row{
  margin-top: 10px;
}
.user-profile-page .profile-subsections .right-align{
  display: flex;
  justify-content: flex-end;
}
.user-profile-page .right-align {
  display: flex;
  justify-content: flex-end;
}
.user-profile-page .badgeImage {
  margin-top: 15px;
}
.user-profile-page .profile-subsections .container-organization .see-all{
  color: indigo;
  font-size: 13px;
  font-weight: bold;
  margin-left: 20px;
} 
.user-profile-page .profile-subsections .container-events .see-all{
  color: indigo;
  font-size: 13px;
  font-weight: bold;
  margin-left: 20px;
} 
.user-profile-page .profile-subsections .container-organization .list-organization{
  display: flex;
  padding-left: 20px;
  flex-direction: row;
} 
.user-profile-page .profile-subsections .container-organization .list-organization .org-list-item {
 max-width: 300px;
} 

.user-profile-page .label-profile{
  color : #75EEB7
}
/* adjust layout for smaller screens */
@media (max-width: 991.98px) {

  .user-profile-page .profile-info .profile-stats {
    margin: 5% 0;
    border-top: 1px solid rgb(49, 0, 83);
    padding-top: 5%;
  }

  .user-profile-page .profile-info .edit-profile {
    margin-top: 3%
  }
  .user-profile-page  .btn-edit{
      border : solid 1px indigo;
      background-color : white;
      border-radius: 25px;
      padding-left: 15%;
      padding-right: 15%;
      margin: 3%;
      color : indigo;
  }
  .user-profile-page .profile-info .edit-profile .btn-edit {
      border : solid 1px indigo;
      background-color : white;
      border-radius: 25px;
      padding-left: 3%;
      padding-right: 3%;
      margin: 3%;
      color : indigo;
  }

  .user-profile-page .profile-info .edit-profile .btn-settings {

    border : solid 1px indigo;
    background-color : #75EEB7;
    border-radius: 25px;
    padding-left: 5%;
    padding-right: 5%;
    margin: 3%;
    color : indigo;
    min-width: 150px;
}

  .user-profile-page .profile-info .edit-profile button {
    width: 25%;
  }

  .user-profile-page .profile-info .action-buttons {
    display: flex;
  }

  .user-profile-page .profile-info .action-buttons button {
    width: 50%;
    padding: 2%;
  }
  .user-profile-page .btn-transparent {
    background-color: 'transparent';
  }
  
}