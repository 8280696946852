.invite-follower {

}

.invite-follower .back-button{
    background-color: transparent;
    border-color: transparent;
    margin: 10px 0;
}
.invite-follower .back-button svg{
    color: black;
    font-weight: bold;
    font-size: 30px;
}
.invite-follower  .title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    font-weight: bold;
}


.invite-follower .layout-share{
    background-color: indigo;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 180px;
}
.invite-follower .layout-share p{
    margin-bottom: 0px;
    color: #ffffff;
}
.invite-follower .row {
    justify-content : 'space-between'!important
}
