.vote-info {
    margin-top: 20px;
}

.vote-info .content {
    display: flex;
    background-color:'#dfe0e8';
    padding-left: 15px;
}
.vote-info .content .title {
    margin-right: 15px;
}
.vote-info .header p{
    margin-bottom: 0;
    background-color : indigo;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.vote-info .avatar{
    width: 90px;
    height: 120px;
    object-fit: cover;

}