.message-screen{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
    padding: 0;
    display : flex;
    margin-top: 10px;
}

.message-screen .message-nav{
    flex: 3;
    margin-top: 20px;
    background-color: '#aaaaaa';
}
.message-screen .message-nav .message-input{
    border: 1px gray solid;
    margin-bottom: 5px;
}

.message-screen .chat-nav{
    flex: 1;
    border-right: 2px;
    border-color: gray;
    background-color:indigo;
    padding-top : 10px;
}
.message-screen .chat-nav .chat-list {
    padding-top : 10px
}

.message-screen .chat-nav .area {
    height: 70vh;
}
.message-screen .message-nav .area {
    height: 70vh;
}
/* .message-screen .message-nav .list-messages {
    transform: rotateX(180deg);
} */
.message-screen .message-input .rce-input-buttons button {
    background-color: transparent;
    border-color: transparent;

}
.message-screen .message-input .rce-input-buttons button svg{
    background-color: transparent;
    border-color: transparent;
    color: indigo;
    font-size: 15px;
}
.mobile-message-screen  .back-button{
    background-color: transparent;
    border-color: transparent;
    margin: 10px 0;
}

.mobile-message-screen .mobile-message-input{
    position: fixed;
    bottom: 0px;
    background-color: white;
    padding-bottom: 20px;
    margin-left: -10px;
}
