.profile-page .title {
    color: indigo;
    margin-left: 20px;
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
}

@media screen and (max-width: 991px) {
    .container {
        width: 100% !important;
    }
 }