.event-details .image img, .event-details .map {
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.event-details .map iframe {
  height: 100%;
  width: 100%;
}

.event-details .main-details, .event-details .extra-details {
  margin-top: 5%;
  margin-bottom: 5%;
}

.event-details .details  {
  line-height: 0.5em;
}

.event-details .name-section h1 {
  display: inline;
}

.event-details .name-section span, .event-details .profile-section span, .event-details .details p.time {
  color: gray;
}

.event-details .name-section span, .event-details .profile-section span {
  margin-left: 2%;
  font-size: 0.7em;
}

.event-details .name-section span, .event-details .profile-section .profile {
  padding-left: 0;
}

.event-details .about-header p {
  font-size: 1.2em;
  font-weight: bold;
}

.event-details .details p {
  font-size: 0.7em;
}

.event-details .details p.title {
  font-weight: bold;
}

.event-details .details .icon {
  padding: 0;
  text-align: center;
}

.event-details .details .detail {
  padding: 2%;
}

.event-details p.tag svg {
  font-size: initial;
  margin-right: 2%;
}

.event-details .details .detail .add-to-calender {
  font-weight: bold;
  color: #402366;
}

.event-details .buttons {
  margin-top: 15%;
}

.event-details .btn-rsvp {
  background-color: #75EEB7;
  border: none;
  border-radius: 25px;
  color : indigo;
  padding: 4%;
  margin: 1%;
  width: 80%;
  font-size: 0.7em;
}
.event-details .btn-invite-follower{
  border-color: indigo;
  background-color:transparent;
  border-radius: 25px;;
  color : indigo;
  font-size: 0.7em;
  padding: 4% 15%;
  margin: 1%;
}  
.event-details .react-add-to-calendar__wrapper {
  color: indigo;
  font-weight: bold;
  font-size: 13px;

}