.create-event a 
{
  color:white;
  text-decoration: none;
}
.events-search .create-event
{
  background-color: #75EEB7;
  border-radius: 25px;
  width:100%;
} 
.events-search{
  max-width: 1080px;
}
.events-search .create-event a
{
  color : indigo;
}
.events-search .apply-filter, .events-search .create-event 
{
  border:none;
}
.events-search .row 
{
  margin-top:1%;
}

.events-search .filter 
{
  font-weight:bold;
  font-size:larger;
  color:indigo;
}
.events-search .text-center{
  display: flex;
}
.events-search .apply-filter
{
  background-color: #75EEB7;
  border-radius: 25px;
  color: indigo;
}

.events-search .clear-filter
{
  margin-left: 15px;
  border-color: indigo;
  border-radius: 25px;
  color : indigo;
}

.events-search .form-label 
{
  color:gray;
  font-size:small;
}

.events-search .loading
{
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 50%;   
  
}

.events-search .no-event
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: indigo;
  text-align: center;
  font-weight: bold;
}
.pac-target-input{
  border : solid 2px indigo;
  border-radius: 25px;
}

.layout-search .input-group{
  border : solid 2px indigo;
  border-radius: 25px;
}
.layout-search .input-group .search-input{
  border : solid 0px indigo!important;  
}