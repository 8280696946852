.bar-app-install{
    display: flex;
    align-items: center; 
    margin : 10px 4px;   
}

.button-close{
    background-color: transparent;
    border-color: transparent;
}

.button-close svg{
    color: indigo;
    font-size: 22px;
}
.layout-between{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.app-name{
    padding-left: 10px;
}
.button-install{
    background-color: transparent;
    border-color: transparent;
    color : indigo;
}
.content {
    background-image: url('../resources/bg1.jpg');
    background-size: cover;
    text-align: center;
    min-height: 100vh;
    justify-content: center;
    padding-top: 70px;

}
.content .text-header{
    font-size: 30px;
    color: white;
    font-weight: bold;
}
.content .text-description{
    margin-top: 70px;
    font-size: 40px;
    color: white;
}
.content .btn-get-app{
    background-color: indigo;
    border-color:transparent;
    padding : 10px 20px;
    border-radius: 30px;
}
.content .btn-explorer{
    background-color: transparent;
    border-color: transparent;
    color : white;
}