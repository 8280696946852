.notification-item {
    padding : 25px
}
.notification-item svg{
    color: indigo;
    font-size: 25px;
}
.notification-item .notification-content p{
    font-weight: 600;
    margin-left: 15px;
}
.notification-item .notification-content {
    display : flex;
    margin-left: 34px;
    align-items: center;
}
.notification-item .notification-content p {
    align-items: center;
    margin-bottom: 0;
}
.notification-item .notification-content .button-layout {
    flex-direction: row;
}
.notification-item .notification-content .button-confirm {
    margin-left : 15px;
    margin-right: 15px;
    background-color: transparent;
    border-color: transparent;
}
