.badges-page .badgeImage{
    max-width: 80%;
    height: auto;
}
.badges-page .score{
    color: rgba(0,0,0,0.5);
    font-size: small;
    font-weight: bold;
}
.badges-page .badgeName{
    color: #402366;
    font-size: small;
    margin-top: 10px;
}
.badges-page .required{
    color: rgba(0,0,0,0.5);
    font-size: small;
}

.badges-page .title{
    color: #402366;
    font-size: larger;
    margin-left: 3%;
    font-weight: bold;
    margin-top: 20px;
}