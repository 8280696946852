
.create-post-layout 
{
    /*https://cssgradient.io/*/
    /* background: rgb(103,0,162); */
    background-image: url('../resources/bg1.jpg');
    background-size: cover;
    text-align: center;
    min-height: 100vh;
}
.create-post-wrapper {
  
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-radius: 25px;
  box-shadow: 1.5px 3px 3px 3px rgba(0, 0, 0, 0.15);
  color: #402366;

}

.create-events-btn {
  border-radius: 0;
  padding: 5px 10px;
  display: block;
  color: #75EEB7;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  transition: background-color 0.3s linear;
  border-color: transparent;
  margin-top : 40px;
  margin-bottom: 30px;
}
button.create-events-btn 
{
  border:none;
}
.create-events-btn a {color:#75EEB7; }
/* .create-events-btn:hover {
  background-color: #ddbfff;
} */


.create-post-wrapper .text-area {
  text-align: center;
}
.create-post-wrapper  textarea {
  margin: auto;
  padding: 20px 12px;
  resize: none;
  border-radius: 7px;
  outline: none;
  width: 100%;
  border: 1px solid transparent;
  min-height: 20vh;
}
.create-post-wrapper .text-area textarea::placeholder {
  color: #b3b7bb;
}
.create-post-wrapper .layout-post{
    border: 1px solid #402366;
    border-radius: 25px;
}
.create-post-btn {

  background-color: #402366;
  color: white;

}

.create-post-btn:hover {
  background-color: #281640;
}

.create-post-wrapper .post-icon 
{
  margin-left: 5%;  
  display:inline-flex;
  
}

.post-icon div 
{
  margin-right:5%;
}

.layout-center{
  width: 100%;
  justify-content: center;
  align-items: center;
}

.layout-center .btn-create-post{
  background-color: #75EEB7;
  border-radius:20px;
  padding: 5px 60px;
  color : indigo
}

/*# sourceMappingURL=CreatePost.css.map */

@media screen and (max-width: 991px) {
   .create-post-wrapper {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 20px;
      margin-right: 20px;
      border-radius: 25px;
      box-shadow: 1.5px 3px 3px 3px rgba(0, 0, 0, 0.15);
      color: #402366;
    }
    .create-post-wrapper .form-group .form-control{
     border : solid 0px #000000;
     padding-top: 10px;
    }
    .create-post-wrapper .layout-post {
      border: 0px solid #402366;
    }
    .container{
      width: 90%;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
}