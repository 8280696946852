
.landing-page 
{
    /*https://cssgradient.io/*/
    /* background: rgb(103,0,162); */
    background-image: url('../resources/bg_landing_page.png');
    background-size: cover;
    text-align: center;
    color:white;
    
    min-height: 100vh;
}
.landing-page .bg-nav{
    background-color: #4c3474;
}
.landing-page .login-signup{
    margin-top: 25px;
}
.landing-page .layout_login{
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}
.landing-page .layout_login .desc-welcome{
    font-weight: 500;
    font-size: 20px;
}
.landing-page .layout_login .title{
    color : #0c0e0d;
    font-size : 45px;
    color : #75EEB7;
    font-weight: bold;
}

.landing-page .layout_login .form-title{
    color : #75EEB7;
    font-size : 40px;
}
.landing-page .layout_login .form-group{
    color : #75EEB7;
    font-size : 40px;
}
.landing-page .layout_login .form-input{
    border-radius: 1.0rem;
}
.landing-page .layout_login .form-label{
    color : #75EEB7;
    font-size : 20px;
    text-align: start;
    width : 100%;
}
.landing-page .layout_login .button-submit{
    background-color : #75EEB7;
    border-radius: 1.0rem;
    font-size : 20px;
    margin-top: 20px;
    margin-bottom: 5px;
}
.landing-page .layout_login span{
    font-size : 20px;
    font-weight: bold;
}

.landing-page .login-signup 
{
}

.landing-page .header 
{
    color : #75EEB7;
    font-size : 40px;
    font-weight: bold;
    
}

.landing-page button 
{
    background-color: greenyellow;
    color:black;
    width: 100%;
}

.landing-page .form-group {width:100%;}