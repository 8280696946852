.dropdown-menu {
    border-radius: 0;
}

.modal-content {
    border-radius: 0;
}

.flex-group {
    display: flex;
    background-color: #f7f7f9;
    align-items: center;
}

.flex-group input:active, .flex-group input:focus {
    box-shadow: none;
}

.flex-group i {
    margin-left: 15px;
}

.navbar {
    padding: 1.5rem 1rem;
}

@media screen and (max-width: 600px) {
    .jumbotron .display-4 {
        font-size: 2.25rem;
        hyphens: auto;
        word-break: break-all;
    }
}