.resource-grid {
  margin: 0px;
}

.resource-grid .list .list-item {
  border-top: 1px solid gray;
  padding: 1.5%;
}

.resource-grid .list .list-item:last-of-type {
  border-bottom: 1px solid gray;
}

.resource-grid .list .list-item h1 {
  font-size: 1em;
  margin: 0px;
}

.resource-grid .cards .col {
  margin-bottom: 3%;
}

.resource-grid .cards .card {
  border: 1px solid gray;
  padding: 5%;
  height: 100%;
}

.resource-grid .cards .card img {
  width: 100%;
}

.resource-grid .cards .card h1 {
  font-size: 1.5em;
  margin-top: 3%;
}

.resource-grid .cards .card p {
  height: 100%;
  font-size: 0.7em;
}

.resource-grid .cards .card button {
  background-color: white;
  border: 1px solid indigo;
  border-radius: 25px;
  color: #000;
  padding: 4%;
  width: 80%;
  display: block;
  margin: auto;
}

.resource-grid .btn-see-more{
  border: 1px solid indigo;
}