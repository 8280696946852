.settings .toggles p, .settings h1 {
  font-weight: bold;
}

.settings .toggles p {
  margin-top: 5%;
  margin-bottom: 0;
}

.settings .toggles .sc-bdVaJa {
  margin: 3%;
}

.settings .toggles button {
  background-color: #402366;
  border: none;
  border-radius: 0;
  padding: 4%;
  margin: 1%;
  width: 100%;
  font-size: 0.7em;
  font-weight: bold;
}

.settings .toggles button.log-out {
  background-color: lightgray;
  color: darkgray;
}

.settings .toggles button.delete-account {
  background-color: transparent;
  color: red;
}

.settings .actions button {
  background-color: transparent;
  position: relative;
  width: 100%;
  border: none;
  border-radius: 0;
  border-top: 1px solid lightgray;
  height: 75px;
}

.settings .actions a:last-of-type button {
  border-bottom: 1px solid lightgray;
}

.settings .actions button h2, .settings .actions button p, .settings .actions button svg {
  color: gray;
  text-align: left;
  margin: 0;
}

.settings .actions button p:nth-of-type(1) {
  font-size: 0.8em;
  font-weight: 400;
}

.settings .actions button p:nth-of-type(2) {
  font-size: 0.5em;
}

.settings .actions button svg {
  position: absolute;
  right: 0;
  top: 40%;
}

@media (max-width: 991.9px) {

  /* remove overlapping border */
  .settings > div:nth-of-type(2) > div:nth-of-type(2) a:last-of-type button {
    border-bottom: none;
  }

}

.settings .hide {
  display: none;
}