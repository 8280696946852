
.back-button {
  background-color: transparent;
  border-color: transparent;
  margin: 10px 0;
}
.back-button span svg{
  font-size: 30px;
  color: black;
}

.resource-page .search-header {
  margin-top: 10px;
  margin-bottom: 10px;
}

.resource-page .search {
  position: relative;
  margin: 10px 0px;
}

.resource-page .search > svg:first-of-type {
  position: absolute;
  right: 7%;
  height: 100%;
}

.resource-page .search input {
  width: 95%;
}

.resource-page .search > div {
  width: 5%;
  display: inline-block;
  text-align: center;
}

.resource-page .search button p {
  font-size: 5%;
  margin: 0;
}

.resource-page .icons {
  display: flex;
  justify-content: flex-end;
}

.resource-page .icons svg {
  font-size: 1.5em;
}

.resource-page .active {
  color: indigo;
}

.resource-page .hidden {
  display: none;
}
.resource-page .search-input {
  border: solid 2px indigo;
  border-radius: 25px;
}
.resource-page .search-input .form-control{
  border-color: transparent;
}
.resource-page .search-input .btn-outline-secondary{
  border-color: transparent;
}
.resource-page .search-input .form-control:hover{
  border-color: transparent;
}
