
.main-nav span {
    font-size:12px;
    /* display:inherit; */
    font-weight: bold;
}

.main-nav svg 
{
    font-size:40px;
}

.main-nav .nav-item{
    display: flex;
    align-items: center;
}

.main-nav span{
    margin-left: 0pxw;
}
@media (max-width: 576px)
{
    .main-nav svg
    {
        font-size:20px;
    }
}

@media (max-width: 768px)
{
    .logo
    {
        max-width: 160px;
        height: auto;
    }
}

.main-nav *, .side-nav *
{
    color:white;
}
.logout {
    padding:2%
}
.profile-img {
    border: 1px solid transparent;
    border-color: white;
    width: 50px;
    height: 50px;
}

.turnup-nav {
    background: #402366 0% 0% no-repeat padding-box;
}

.turnup-nav .nav-bar{
    color: white;
}
.main-nav a  {
    text-align: center;
}

.main-nav {
    display:inline-flex;
}

.side-nav {
    display:flex;
}

.side-nav a{
    margin-right:5%;
    align-self: start;
    margin-top: 15px;
}

.main-nav a {
    margin-right:5%;
}
.side-nav a svg {
    font-size: 22px;
}

.main-nav .dropdown-item {
    color:#402366;
}

.main-nav .nav-item-resource .dropdown-menu{
    min-width: 200px;
}
.main-nav .nav-item-resource .dropdown-toggle {
    font-size: 12px;
    color: white;
    font-weight: bold;
}
.main-nav .nav-item-resource{
    background : 'transparent';
}
.side-nav .nav-item-profile .dropdown-menu{
    min-width: 200px;
}
.side-nav .nav-item-profile .dropdown-toggle {
    font-size: 12px;
    color: white;
    font-weight: bold;
}
.side-nav .dropdown-item{
    color:#402366;
}
.main-nav .nav-item-resource{
    background : 'transparent';
}

.main-nav #basic-nav-dropdown {
    margin-left: 10px;
}

@media (max-width: 991.98px) {
    .main-nav {
        width: 100%;
    }
    .main-nav svg{
        font-size: 0px;
        display : none;

    }
    .main-nav span{
        font-size : 15px;
        font-weight: 400;
    }
    .navbar-light .navbar-nav .nav-link {
        color: rgba(0,0,0,.5);
        width : 100%;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid white;
    }
}