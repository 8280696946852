.profile-list{
    width: 100%;
}
.profile-list .profile-wrapper{
    display: flex;
    justify-content: space-between;
    z-index: 10;
}
.profile-list .profile-wrapper .info-wrapper{
    display: flex;
    flex-wrap: wrap;
}


.profile-list .profile-wrapper .image {
    width: 90px;
    height: 90px;
    border-radius: 50px;
    z-index: 10;
}
.profile-list .profile-wrapper .text-name {
    font-size: 15px;
    font-weight: 800;
    z-index: 10;
}
.profile-list .profile-wrapper .text-location {
    font-size: 15px;
    color: gray;
}

.profile-list .profile-wrapper .follow-layout {
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    background-color: transparent;
    border-color: transparent;
}
.profile-list .profile-wrapper .unblock-layout {
    justify-content: center;
}

.profile-list .profile-wrapper .text-status {
    margin-bottom: 0;
    color: indigo;
}
.profile-list .selected-checkbox{
    align-items: center;
    justify-content: center;
}
.profile-list .selected-checkbox input{
    min-width: 50px;
    min-height: 50px;
}
@media screen and (max-width: 991px) {
    .profile-list .profile-wrapper .image {
        width: 60px;
        height: 60px;
        border-radius: 50px;
        z-index: 10;
    }
}