.home-pill-nav{
    margin-top:2%;
    width: 100%;
}

.home-pill-nav .nav-link.active {
    /* font-weight: bold;
    color:gray; */

    font: normal normal  20px/25px Azo Sans;
    letter-spacing: 0px;
    color: #402366;
    background-color: white;
}

.home-pill-nav a {
    color:gray;
    font: normal normal 20px/25px Azo Sans;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
}
.home-pill-nav .nav-item {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
}
.home-container{
    max-width: 718px;
    position: relative;
}
.home-container .top-bar{
    max-width: 718px;
}
/* .home-container .loading{
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.home-container .top-bar{
    position: fixed;
    display: flex;
    z-index: 100;
    background-color: white;  
    width: 100%;  
    justify-content:center;
}
.home-container .top-bar .create-post {
    background-color:#75EEB7;
    border-radius: 25px;
    border-color: transparent;
    color : indigo;
    width: 100%;
    margin-top: 10px;
}
.home-container .top-bar .create-post a{
    color: indigo;
}
.home-container .offset{
    height: 40px;
}
.home-container .home-view{
    padding-top: 100px;
}
.dropdown-toggle{
  width: 100%!important;
  background-color: transparent !important;
  border-color: transparent !important;
  color: white !important;
}
.dropdown-menu {
    width: 100% !important;
}
.reason-dropdown {
    width: 100%;
}
.reason-text {
    min-height: 200px;
}
.no-post{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: indigo;
    text-align: center;
    font-weight: bold;
}
