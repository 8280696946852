
.post-detail .back-button{
    background-color: transparent;
    border-color: transparent;
    margin: 10px 0;
}
.post-detail .post-profile{
    padding-top: 10px;
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;

}
.post-detail .offst {
    height: 95px;
}

.post-detail .profile-layout{
    width: 300px;
}


.post-detail .media{
    margin: -2% -2.5% 2%;
}

.post-detail .content p {
    color: gray;
  }
  
  .post-detail .media {
    margin: -2% -2.5% 2%;
  }
  
  .post-detail .media .media-col {
    padding: 0 0.5% 0.5%;
  }
  
  .post-detail .media .media-col img {
    width: 100%;
  }
  
  .post-detail .media .media-col iframe {
    width: 100%;
  }
  .post-detail .stats {
    margin-top: 2%;
  }
  
  .post-detail .stats span {
    border-radius: 50%;
    background-color: indigo;
    padding: 5px;
    margin-right: 5px;
  }
  
  .post-detail .stats svg {
    color: white;
    font-size: 100%;
  }
  
  .post-detail .stats .icon-wrapper {
    display: flex;
  }
  
  .post-detail .stats p {
    display: flex;
    margin-right: 2%;
    justify-content: center;
    align-items: center;
  }
  
  .post-detail .stats p span{
    display: flex;
  }

  .post-detail .input-container{
      position: fixed;
      bottom: 0px;
      background-color: white;
      padding-bottom: 20px;
      width: 90%;
  }
  .post-detail .input-container input{
    width: 80%;
}
  .post-detail .input-container .post-button{
        background-color: transparent;
        border-color: transparent;
  }
  .post-detail .input-container .post-button span{
    background-color: transparent;
    border-color: transparent;
    color: indigo;
    font-weight: bold;
    font-size: 15px;
}

.post-detail .layout-comment {
    margin-bottom: 50px;
    padding: auto 15px;
    
}
.post-detail .layout-comment .comment{
    margin-bottom: 5px;
    padding: auto 15px;
    display: flex;
    width: 100%;
}
.post-detail .layout-comment .comment-text{
    margin-bottom: 50px;
    padding: auto 15px;
    display: flex;
    width: 100%;
    background-color: lightgray;
    border-radius: 5px;
}
.post-detail .layout-comment .comment-text{
    margin-bottom: 50px;
    padding: auto 15px;
    display: flex;
    width: 100%;
    background-color: lightgray;
    border-radius: 5px;
}
.post-detail .layout-comment .comment-text p{
   font-size: 14px;
}
.profile-picture {  
    width: 47px;
    height: 47px;
    border-radius: 23.5px;
    margin-right: 10px;
    resize: 'contain';
    max-width: 47px;
}
.action-row{
  margin-bottom: 10px;
}
.action-row svg
{
  color: gray;
}
.action-row .button-center{
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
.post-detail svg {
    font-size: 30px;
}