.post {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
  padding: 2%;
}

.post svg {
  font-size: 30px;
}


.action-row svg
{
  color: gray;
}
.post .dots {
  float: right;
  color: indigo;
}

.post .time p {
  color: darkgray;
  text-align: right;
}

.post .content p {
  color: gray;
}

.post .media {
  margin: -2% -2.5% 2%;
}

.post .media .media-col {
  padding: 0 0.5% 0.5%;
}

.post .media .media-col img {
  width: 100%;
}
.post .media .slider-wrapper {
  align-items: center;
  justify-content:center;
}

.post .media .media-col iframe {
  width: 100%;
}

.post .stats {
  margin-top: 2%;
}

.post .stats span {
  border-radius: 50%;
  background-color: indigo;
  padding: 5px;
  margin-right: 5px;
}

.post .stats svg {
  color: white;
  font-size: 100%;
}

.post .stats .icon-wrapper {
  display: flex;
}

.post .stats p {
  display: flex;
  margin-right: 2%;
  justify-content: center;
  align-items: center;
}

.post .stats p span{
  display: flex;
}
.action {
  background-color: transparent;
  border: none;
  width: 100%;
}
.post .button {
  background-color: #75EEB7;
  border: none;
  border-radius: 25px;
  color : indigo;
  padding: 2%;
  width: 100%;
}

.action:focus {
  outline: 0;
}

.active {
  color: indigo !important;
}

.post .title{
  color: indigo;
  font-size: medium;
  font-weight: bold;
}
.post .event-detail p{
  color: indigo;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.post .event-detail Col{
  display: flex;
  flex-direction: row;
  color: indigo;
}
.post .event-detail p span{
  color: indigo;
  font-weight: bold;
  margin-left: 3px;
}

@media screen and (max-width: 600px) {
  .post .event-detail p{
    color: indigo;
    font-weight: bold;
    font-size: 10px;
  } 
  .post .event-detail p .icon{
    height: 2em;
  }
}