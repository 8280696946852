
.create-event-layout {
    background-image: url('../resources/bg1.jpg');
    background-size: cover;
    text-align: center;
    min-height: 100vh;
}
.create-event h3{
    color:#75EEB7;
    padding-top: 40px;
    padding-bottom: 40px;
}
.create-event button, .modal-content button:not(.close)
{
    color:white;
    background-color: indigo;
}
.create-event .btn-create-event{
    background-color: #75EEB7;
    border: none;
    border-radius: 25px;
    color : indigo;
    padding: 4%;
    margin: 1%;
    width: 80%;
    font-size: 0.7em;
}
.create-event label
{
    font-size: smaller;
    font-weight: bold;
}
.create-event .form-label{
    color : #ffffff
}

.create-event .form-label{
    color : #ffffff
}
.create-event .form-control{
    border-radius : 25px;
}
.create-event .submit {width:100%;}
.create-event .layout-flex{
    display : flex;
    justify-content: center;
}
.create-event .layout-about{
    margin-top: 20px;
    margin-bottom: 15px;
}
.modal-header
{
    border: none;
    display: block;
    padding-left: 0;
}

.modal-content button.close
{
    float:left;
    opacity: 1;
}

.modal-content button.close span
{
    color: black;
    font-size: 2em;
    font-weight: normal;
    line-height: 50%;
}

.modal-content button:not(.close)
{
    width: 50%;
    margin: auto;
    margin-bottom: 10%;
}