.phone-confirm {
    width: 100%;
    height: 100vh;
    background-image: url('../resources/bg_landing_page.png');
    background-size: cover;
    padding-left: 20%;
    padding-right: 20%;
}
.phone-confirm .desc-welcome{
    font-weight: 500;
    font-size: 20px;
    color: white;
    text-align: center;
}
.phone-confirm .title{
    color : #0c0e0d;
    font-size : 45px;
    color : #75EEB7;
    font-weight: bold;
    text-align: center;
}

.phone-confirm .row:nth-of-type(1) {
    justify-content: center;
}

.phone-confirm h1, .phone-confirm .form-label, .phone-confirm button {
    font-family: Azo Sans;
    font-weight: bold;
}

.phone-confirm h1 {
    font-size: 1.2em;
    color: black;
    padding: 3%;
}
.phone-confirm .row{    
    justify-content: center;
}
.phone-confirm .welcome{    
    justify-content: center;
}
.phone-confirm img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}
.phone-confirm .form-title{
    color : #75EEB7;
    font-size : 40px;
    margin-top: 30px;
}

.phone-confirm .form-control {
    border: 2px solid dimgray;
    height: 50px;
    border-radius: 1.0rem;
}
.phone-confirm .form-label{
    color : #75EEB7;
    font-size : 20px;
    text-align: start;
    width : 100%;
}
.phone-confirm .button-submit {
    background-color : #75EEB7;
    border-radius: 1.0rem;
    font-size : 20px;
    margin-top: 20px;
    margin-bottom: 5px;
    color: #402366;
}


.phone-confirm button {
    margin-top: 5%;
    background: #402366;
    width: 60%;
    height: 50px;
    border-radius: 0;
    border: none;
}

.phone-confirm .resend-code {
    width: 60%;
    font-size: 0.7em;
    text-align: center;
    margin-top: 3%;
}

.phone-confirm .resend-code p {
    margin-bottom: 3%;
    color: #ffffff;
}

.phone-confirm .resend-code span {
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
}

